import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import infoGreen from "../images/info-green.svg"
import linkGreen from "../images/link-out-green.svg"
import AlertsSlider from "../components/alertsslider"
import SEO from "../components/seo"

class PageTemplate extends Component {
  render() {
    const currentPage = this.props.data.nodePage
    const landingPages = this.props.data.allNodeLandingPage.edges
    const externalLandingPages = this.props.data.allNodeLandingPageExternal.edges
    const alerts = this.props.data.allNodeAlert
    const infoNservices = this.props.data.allNodeInfoAndServices.edges
    var departments = [];
    var department = {};
    const meta = currentPage.relationships.field_meta_data
    infoNservices.sort((a,b) => b.node.field_include_in_list - a.node.field_include_in_list)


    landingPages.map(landing => {
      department = {}
      department.address = []
      department.phone = []
      landing.node.relationships.field_landing_flexible.map(flexible => {
        if(flexible.__typename === 'paragraph__agency_overview') {
          flexible.relationships.field_contact.map(contact => {
            if(contact.__typename === 'paragraph__contact_address') {
              department.address.push(contact.field_address + ' ' + contact.field_address_line_2)
            } else if(contact.__typename === 'paragraph__contact_phone') {
              department.phone.push(contact.field_phone)
            }
            return contact
          });
        }
        return flexible
      });
      if(landing.node.relationships.field_hero != null && landing.node.relationships.field_hero.field_title_html != null) {
        department.name = landing.node.relationships.field_hero.field_title_html.value
      } else {
        department.name = landing.node.title
      }
      department.website = landing.node.path.alias
      departments.push(department)
      return landing
    });

    externalLandingPages.map(landing => {
      department = {}
      department.address = []
      department.phone = []
      if(landing.node.field_address_line_1 !== null && landing.node.field_address_line_2 !== null) {
        department.address.push(landing.node.field_address_line_1 + ' ' + landing.node.field_address_line_2)
      } else if (landing.node.field_address_line_1 !== null) {
        department.address.push(landing.node.field_address_line_1)
      } else if(landing.node.field_address_line_2 !== null) {
        department.address.push(landing.node.field_address_line_2)
      }
      department.phone.push(landing.node.field_phone)
      department.name = landing.node.title
      department.external = 'yes'
      landing.node.relationships.field_dept_social_links.map(social => {
        if(social.field_social_network === 'website') {
          department.website = social.field_link.uri
        } else if(social.field_social_network === 'facebook') {
          department.facebook = social.field_link.uri
        } else if(social.field_social_network === 'twitter') {
          department.twitter = social.field_link.uri
        } else if(social.field_social_network === 'youtube') {
          department.youtube = social.field_link.uri
        } else if(social.field_social_network === 'flickr') {
          department.flickr = social.field_link.uri
        }
        return social
      })
      departments.push(department)
      return landing
    });
    departments.sort(function(a, b) {
        return a.name.localeCompare(b.name)
    });

    return (
        <Layout >
        <SEO
            title={currentPage.title}
            metaData={meta}
            contentBody={currentPage.body}
            locationOrigin={this.props.location.origin + currentPage.path.alias}
            domen={this.props.location.origin}
        />

        <div className="site-content directory">
          <section className="pwc-directory uk-padding-remove-top">
            <AlertsSlider allAlerts={alerts.edges} gruopId={null} globalPage={true} />
            <div className="container uk-margin-medium-top">
              <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }} />
              <div className="entry-content">
                {currentPage.body && currentPage.body.processed ? (
                  <div dangerouslySetInnerHTML={{ __html: currentPage.body.processed }} />
                ) : (
                  null
                )}

                  {/*<Wysiwyg wysiwygJson={currentPage.content} mediaJson={wpMedia} />*/}
              </div>
              <ul id="switcher-tabs" className="uk-grid uk-child-width-1-3@m uk-subnav uk-subnav-pill uk-flex tab-directory-list" data-uk-switcher="animation: uk-animation-fade">
                <li id="departments"><a className="departments tab-link" href="#">Departments</a></li>
                <li  id="info-services"><a className="info-services tab-link" href="#">Info &amp; Services</a></li>
              </ul>
              <div className="uk-switcher tab-content">
                <div className="responsive-document-table">
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">DEPARTMENT</th>
                        <th scope="col">ADDRESS</th>
                        <th scope="col">PHONE</th>
                        <th scope="col">ONLINE CONTACT</th>
                      </tr>
                    </thead>
                    <tbody data-uk-accordion="content: + .ci-accordion-content .info-content; targets: > .main-row; multiple: true">
                      {departments.map((row, key) => (
                        <tr className="main-row" key={key}>
                          <td data-label="DEPARTMENT">{row.name}</td>
                          {row.address.length > 0 ? (
                            <td data-label="ADDRESS" dangerouslySetInnerHTML={{ __html: row.address[0]}} />
                          ) : (
                            <td data-label="ADDRESS"></td>
                          )}
                          {row.phone.length > 0 ? (
                            <td data-label="PHONE">{row.phone[0]}</td>
                          ) : (
                            <td data-label="PHONE"></td>
                          )}
                          <td className="online-contact" data-label="ONLINE CONTACT">
                            <div className="uk-grid uk-child-width-auto" data-uk-grid>
                              <div>
                                {row.website && row.website !== null && row.external ? (
                                  <a className="website" target="_blank" rel="noreferrer" href={row.website}>{row.website}</a>
                                ) : (
                                  row.website && row.website !== null ? (
                                    <Link className="website" to={row.website}>{row.website}</Link>
                                  ) : (
                                    null
                                  )
                                )}
                              </div>
                              <div>
                                {row.twitter && row.twitter !== null ? (
                                  <a className="twitter" target="_blank" rel="noreferrer" href={row.twitter}>{row.twitter}</a>
                                ) : (
                                  null
                                )}
                              </div>
                              <div>
                                {row.facebook && row.facebook !== null ? (
                                  <a className="facebook" target="_blank" rel="noreferrer" href={row.facebook}>{row.facebook}</a>
                                ) : (
                                  null
                                )}
                              </div>
                              <div>
                                {row.youtube && row.youtube !== null ? (
                                  <a className="youtube" target="_blank" rel="noreferrer" href={row.youtube}>{row.youtube}</a>
                                ) : (
                                  null
                                )}
                              </div>
                              <div>
                                {row.flickr && row.flickr !== null ? (
                                  <a className="flickr" target="_blank" rel="noreferrer" href={row.flickr}>{row.flickr}</a>
                                ) : (
                                  null
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="responsive-document-table">
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Info or Service</th>
                        <th scope="col">Primary Category</th>
                        <th scope="col">Organization</th>
                        <th className="online-th" scope="col">URL</th>
                      </tr>
                    </thead>
                    <tbody data-uk-accordion="content: + .ci-accordion-content .info-content; targets: > .main-row; multiple: true">
                      {infoNservices.map((service, key) => (
                        <tr className="main-row" key={key}>
                          <td data-label="TYPE">{service.node.title}</td>
                          <td data-label="Primary Category">{service.node.field_phone ? ( service.node.field_phone ) : ( null )}</td>
                          <td data-label="Organization">{service.node.field_title ? ( service.node.field_title ) : ( null )}</td>
                          <td className="online-contact" data-label="URL">{service.node.field_link.uri ? ( <a className="website" href={service.node.field_link.uri.replace("internal:","")}>{service.node.field_link.uri.replace("internal:","")}</a> ) : ( null )}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>



        </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    nodePage(id: { eq: $id }) {
      title
      body {
        value
        processed
      }
      path {
        alias
      }
      relationships {
        field_meta_data {
          ...paragraph__meta_dataFragment
        }
      }
    }
    allNodeLandingPage(filter: {field_include_in_list: {eq: true}}, sort: {fields: title, order: ASC}) {
      edges {
        node {
          relationships {
            field_hero {
              field_title_html {
                value
              }
            }
            field_landing_flexible {
              ... on paragraph__agency_overview {
                id
                relationships {
                  field_contact {
                    ... on paragraph__contact_address {
                      field_address
                      field_address_line_2
                    }
                    ... on paragraph__contact_email {
                      field_email
                    }
                    ... on paragraph__contact_phone {
                      field_phone
                    }
                  }
                }
              }
            }
          }
          title
          path {
            alias
          }
        }
      }
    }

    allNodeAlert(filter: {field_global_post: {eq: true}},sort: {fields: field_weight, order: ASC}) {
      edges {
        node {
          title
          field_alert_text
          field_weight
          field_link {
            title
            uri
          }
          field_alert_type
          field_global_post
          relationships {
            group_content__departments_group_node_alert {
              relationships {
                gid {
                  drupal_internal__id
                }
              }
            }
          }
        }
      }
    }

    allNodeLandingPageExternal(filter: {drupal_internal__nid: {ne: 355}}) {
      edges {
        node {
          field_address_line_1
          field_address_line_2
          relationships {
            field_dept_social_links {
              field_link {
                title
                uri
              }
              field_social_network
            }
          }
          field_phone
          title
        }
      }
    }
    allNodeInfoAndServices(sort: {fields: field_title, order: ASC}) {
      edges {
        node {
          field_phone
          field_title
          field_link {
            uri
          }
          title
          field_include_in_list
        }
      }
    }
  }
`
